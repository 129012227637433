import { useState } from 'react'
import {
  LabelingDialog,
  buildUrl,
  AddToLabelingQueueParams,
} from './components/LabelingDialog'
import { Button } from './components/Button'
import { fetch_ } from 'shared/utils/fetch'
import { onError } from 'shared/utils/web/error'

type Props = {
  serial: string
  currentTime: string
}

export function EventDialog({ serial, currentTime }: Props) {
  const [showLabelsDialog, setshowLabelsDialog] = useState(false)

  async function handleAddToLabelingQueue() {
    const payload = [
      {
        instants: [`${serial}/${currentTime}`],
        source: AddToLabelingQueueParams.source,
        why: AddToLabelingQueueParams.why,
        priority: AddToLabelingQueueParams.priority,
        labelersTarget: AddToLabelingQueueParams.labelersTarget,
      },
    ]

    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    }

    const url = buildUrl('event', 'addToLabelingQueue')

    fetch_(url, options).catch(onError)
  }

  function handleLabeling() {
    const url = `${buildUrl('event', 'openInApp')}/${serial}/${currentTime}`
    window.open(url, '_blank', 'noopener,noreferrer')
  }

  return (
    <>
      <div onClick={() => setshowLabelsDialog(true)}>
        <Button>Event</Button>
      </div>
      <LabelingDialog
        open={showLabelsDialog}
        onClose={() => setshowLabelsDialog(false)}
        onLabel={() => handleLabeling()}
        onAddToQueue={() => handleAddToLabelingQueue()}
      >
        <div className="mt-5 flex items-center justify-center text-lg">
          Choisissez une action
        </div>
      </LabelingDialog>
    </>
  )
}
