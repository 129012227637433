import { Dialog as SharedDialog } from 'shared/components/Dialog'
import { Button } from './Button'
import { osoDomainName } from 'shared/utils/url'

type DialogProps = {
  open: boolean
  onClose: () => void
  onLabel: () => void
  onAddToQueue: () => void
  children: React.ReactNode
}

export const LabelingDialog = ({
  open,
  onClose,
  onLabel,
  onAddToQueue,
  children,
}: DialogProps) => {
  if (!open) return null

  function handleLabeling() {
    onLabel()
    onClose()
  }

  function handleAddToQueue() {
    onAddToQueue()
    onClose()
  }

  return (
    <SharedDialog onClose={onClose}>
      <div className="bg-background flex flex-col gap-4 rounded-md p-4">
        {children}
        <div className="flex flex-row justify-evenly gap-2">
          <Button primary onClick={handleLabeling} type="submit">
            Labelliser
          </Button>
          <Button primary onClick={handleAddToQueue} type="submit">
            Ajouter à la file d'attente
          </Button>
          <Button onClick={onClose} type="reset">
            Annuler
          </Button>
        </div>
      </div>
    </SharedDialog>
  )
}

export const AddToLabelingQueueParams = {
  source: 'oso-listen',
  why: 'unknown',
  priority: 2,
  labelersTarget: 3,
}

export function buildUrl(
  project: 'sequence' | 'event' | 'labels',
  action: 'addToLabelingQueue' | 'openInApp',
): string {
  let url: string

  if (action === 'addToLabelingQueue') {
    const domain =
      import.meta.env.MODE === 'production'
        ? `europe-west1-oso-${project}.cloudfunctions.net`
        : `europe-west1-oso-${project}-staging.cloudfunctions.net`

    const path =
      project === 'sequence'
        ? 'addInstantsToLabelingQueue'
        : project === 'event'
          ? 'addEventsToLabelingQueue'
          : 'addSoundsToLabelingQueue'

    url = `https://${domain}/${path}`
  } else {
    url =
      import.meta.env.MODE === 'production'
        ? `https://${project}.${osoDomainName}`
        : `https://oso-${project}-staging.web.app`
  }
  console.log(url)
  return url
}
