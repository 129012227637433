import React from 'react'
import { cn } from 'shared/utils/web/cn'
import { alertTypeColor, alertTypeString, AlertType } from 'shared/types/alert'
import { verticalBlockStyle } from 'shared/components/Predictions'
import { SOUND_DURATION } from '../Explorer'

type Alert = {
  validated: AlertType[]
  recorded: AlertType[]
}

type AlertsVisualizerProps = {
  alerts: Record<number, Alert>
  start: number
  end: number
  className?: string
}

function isAlertType(type: string): type is AlertType {
  return Object.keys(alertTypeColor).includes(type)
}

export const AlertsVisualizer: React.FC<AlertsVisualizerProps> = ({
  alerts,
  start,
  end,
  className,
}) => (
  <div className={`relative ${className ?? ''}`}>
    {Object.keys(alerts).map((rawTimestamp) => {
      const ts = parseInt(rawTimestamp, 10)

      const left = Math.max(ts - SOUND_DURATION, start)
      const right = Math.min(ts, end)

      if (!alerts[ts]) return null
      if (right < left) return null

      const validatedTooltip = `Alertes validées : ${alerts[ts].validated
        .filter((type) => isAlertType(type))
        .map((type) => alertTypeString[type])
        .join(', ')} \n`
      const recordedTooltip = `Alertes enregistrées : ${alerts[ts].recorded
        .filter((type) => isAlertType(type))
        .map((type) => alertTypeString[type])
        .join(', ')}`

      const tooltip = `${
        alerts[ts].validated.length > 0 ? validatedTooltip : ''
      }${alerts[ts].recorded.length > 0 ? recordedTooltip : ''}`

      return (
        <div
          key={ts}
          title={tooltip}
          className="absolute flex items-center justify-center"
          style={{
            ...verticalBlockStyle(left, right, start, end - start),
          }}
        >
          <div
            className={cn(
              'h-3 w-3 rounded-full',
              alerts[ts].validated.length > 0
                ? `border-2 border-white ${alertTypeColor[alerts[ts].validated[0]]}`
                : `bg-yellow-300 ${alertTypeColor[alerts[ts].recorded[0]]}`,
            )}
          />
        </div>
      )
    })}
  </div>
)
