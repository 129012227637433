import { useEffect, useState } from 'react'
import {
  GetAlertsParams,
  GetAlertsResponseData,
} from '../../functions/src/types'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { app } from '../firebase'
import { DEFAULT_REGION } from 'shared/firebase/region'
import { Serial } from 'shared/types/utils'
import { alertTypeString } from 'shared/types/alert'

const functions = getFunctions(app, DEFAULT_REGION)
const getAlerts = httpsCallable<GetAlertsParams, GetAlertsResponseData>(
  functions,
  'getAlerts',
)

export const useAlerts = (serial: Serial, start: number, end: number) => {
  const [alerts, setAlerts] = useState<GetAlertsResponseData>({})

  useEffect(() => {
    async function reloadPredictions() {
      const result = await getAlerts({
        types: Object.keys(alertTypeString),
        serial,
        start,
        end,
      })

      setAlerts(result.data)
    }

    reloadPredictions()
  }, [serial, end, start])

  return alerts
}
